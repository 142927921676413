<template>
  <div class="configurations-table">
    <mi-table
      ref="miTable"
      :columns="table.columns"
      :filter="configurationsSearchString"
      :loading="areConfigurationsLoading"
      :rows="table.rows"
      :custom-header="true"
      :on-change-sort-table-column-visibility="tableCallbacks.onChangeSortTableColumnVisibility"
      :on-change-sort-table-direction="tableCallbacks.onChangeSortTableDirection"
      class="configurations-table q-px-lg q-pb-lg"
      column-sort-order="da"
      loading-label="Loading configurations. Please wait ..."
      no-data-label="No configurations found"
      row-key="id"
      hide-pagination
      sticky-header
      virtual-scroll
      striped
      title="Configurations"
    >
      <!-- Description column -->
      <template #body-cell-description="{ value, row }">
        <!-- Validation status -->
        <mi-td>
          <div class="flex items-center no-wrap">
            <span
              class="configurations-table__description ellipsis-2-lines q-mr-xs relative-position text-left"
            >
              {{ value }}
            </span>
            <div class="flex no-wrap q-ml-auto">
              <!-- Open configuration button -->
              <mi-tr-action-btn icon="exit" @click="openConfiguration(row.id)">
                <mi-tooltip> Open </mi-tooltip>
              </mi-tr-action-btn>

              <!-- Delete configuration button -->
              <mi-tr-action-btn
                icon="trash"
                :disabled="!hasPermissionToDelete(row)"
                @click="openDeleteConfigurationDialog(row)"
              >
                <mi-tooltip :hidden="!hasPermissionToDelete(row) ">
                  Delete
                </mi-tooltip>
              </mi-tr-action-btn>
            </div>
          </div>
        </mi-td>
      </template>
    </mi-table>
  </div>
  <div
    v-if="!!table.rows.length"
    class="configurations-table__pagination"
  >
    <mi-pagination
      :model-value="pagination.currentPage"
      :max="pagination.totalPages"
      :max-pages="pagination.maxNavPage"
      size="14px"
      boundary-links
      direction-links
      boundary-numbers
      ellipses
      @update:model-value="handlePaginationChange"
    ></mi-pagination>
  </div>
  <!-- Configuration delete dialog -->
  <configuration-delete-dialog
    v-model="isDeleteConfigurationDialogShown"
    :configuration="configurationToDelete"
    @delete:configuration="handleConfigurationDeletedEvent"
  ></configuration-delete-dialog>

  <learn-more-pac-modal :show-modal="showLearnMoreModal" @update:show-modal="showLearnMoreModal = false">
  </learn-more-pac-modal>
</template>

  <script>
  import { onMounted, watch, ref, computed, onUnmounted, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { usePagination } from '@/composables/usePagination'
  import { useTable } from '@/composables/useTable'
  import ConfigurationDeleteDialog from '@/components/pac/configuration/dialogs/ConfigurationDeleteDialog.vue'
  import MiPagination from '@/packages/@mi-library/MiPagination/MiPagination.vue'
  import { getConfigurations } from '@/api'
  import { configurationsTableColumns } from '@/components/pac/configurations/utils'
  import { PAC_DELETED_CONFIG } from '@/utils/analytics/constants'
  import { PAC_CONFIGURATION_ROUTE } from '@/router/routeNames'
  import { SET_CONFIGURATIONS_SEARCH_STRING } from '@/store/modules/pac/mutationTypes'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import LearnMorePacModal from '../LearnMorePacModal.vue'

  export default {
    name: 'ConfigurationsTable',
    components: { LearnMorePacModal, MiPagination, ConfigurationDeleteDialog },
    setup() {
      const areConfigurationsLoading = ref(false)
      const configurationToDelete = ref(null)
      const isDeleteConfigurationDialogShown = ref(false)
      const { proxy } = getCurrentInstance()
      const showLearnMoreModal = ref(
        proxy.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_LEARN_MORE_PAC_MODAL) === null
      )

      const { pagination, handleChange: handlePaginationChange } = usePagination({ pageSize: 20 })
      const { table, callbacks: tableCallbacks } = useTable(configurationsTableColumns)

      const router = useRouter()
      const { state: { user, pac: pacStore }, commit } = useStore()
      const usePacMutation = name => `pac/${ name }`
      const configurationsSearchString = computed(() => pacStore.configurationsSearchString)

      const hasPermissionToDelete = configuration => {
        const { authorizedUsers = [] } = configuration
        if (authorizedUsers.length) return authorizedUsers.includes(user.username)
        return true
      }

      const buildUrlSearchParams = () => {
        const params = new URLSearchParams()
        params.append('page', pagination.currentPage - 1)
        params.append('size', pagination.pageSize)
        params.append('sortBy', table.sort?.sortBy || 'modifiedDate')
        params.append('sortDirection', table.sort?.sortDirection || 'DESC')
        configurationsSearchString.value && params.append('search', configurationsSearchString.value)

        return params.toString()
      }

      const fetchConfigurationResults = async () => {
        areConfigurationsLoading.value = true
        const params = buildUrlSearchParams()

        const fetchConfigurationResultsPromise = () => getConfigurations(params)
        const data = await tableCallbacks.fetchTableData(fetchConfigurationResultsPromise)

        pagination.totalPages = data.totalPages || 1
        areConfigurationsLoading.value = false
      }

      const handleConfigurationDeletedEvent = async () => {
        await fetchConfigurationResults()
        recordAnalytics(PAC_DELETED_CONFIG)
      }

      const openDeleteConfigurationDialog = configuration => {
        configurationToDelete.value = { id: configuration.id, name: configuration.name }
        isDeleteConfigurationDialogShown.value = true
      }

      const openConfiguration = (id = '') => {
        router.push({ name: PAC_CONFIGURATION_ROUTE.name, params: { id } })
      }

      watch(() => pagination.currentPage, () => {
        fetchConfigurationResults()
      })

      watch(() => [configurationsSearchString.value, table.sort], () => {
        pagination.currentPage === 1 ? fetchConfigurationResults() : handlePaginationChange(1)
      })

      onMounted(async () => {
        await fetchConfigurationResults()
      })

      onUnmounted(() => {
        commit(usePacMutation(SET_CONFIGURATIONS_SEARCH_STRING), '')
      })

      return {
        table,
        pagination,
        handlePaginationChange,
        areConfigurationsLoading,
        handleConfigurationDeletedEvent,
        configurationToDelete,
        openDeleteConfigurationDialog,
        isDeleteConfigurationDialogShown,
        openConfiguration,
        configurationsSearchString,
        buildUrlSearchParams,
        tableCallbacks,
        hasPermissionToDelete,
        showLearnMoreModal
      }
    }
  }
  </script>

<style lang="scss" scoped>
  .configurations-table {
    min-height: 50vh;
    max-height: 75vh;

    &__pagination {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__description {
      white-space: normal;
    }

    // stylelint-disable declaration-no-important
    .mi-btn.q-btn:not(.q-btn--flat):not(.q-btn--outline).disabled,
    .mi-btn.q-btn:not(.q-btn--flat):not(.q-btn--outline)[disabled] {
      opacity: 0 !important;
    }

    tr:hover .mi-btn.q-btn:not(.q-btn--flat):not(.q-btn--outline).disabled,
    tr:hover .mi-btn.q-btn:not(.q-btn--flat):not(.q-btn--outline)[disabled] {
      opacity: 1 !important;
    }

    .mi-btn.q-btn:not(.q-btn--flat):not(.q-btn--outline).disabled:hover,
    .mi-btn.q-btn:not(.q-btn--flat):not(.q-btn--outline)[disabled]:hover {
      background-color: $mi-silver-200 !important;
    }
  }
</style>
